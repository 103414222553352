import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row
} from 'reactstrap';
import get from 'lodash/get';
import { dateFormatter, providerName } from '../../helpers/formatters';
import { isMobileOnly } from 'react-device-detect';

class ProjectInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      postulated: false
    };
  }

  componentWillReceiveProps({ project, postulated }) {
    this.setState({ project, postulated });
  }

  render() {
    let { project, postulated } = this.state;

    return (
      <Card key={'assignClient'} className="card-flex user-data">
        <CardHeader className={'text-center'}>
          <h6>
            <Trans>Project Info</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <CardTitle tag="h5" className={'text-center'}>
            {get(project, 'name', ' ')}
          </CardTitle>
          {project ? (
            <div>
              <ul>
                <li>
                  <Row>
                    <Col xs={6}>
                      <strong className={'mr-1'}>
                        <Trans>Start Date</Trans>:
                      </strong>
                      {dateFormatter(project.startDate)}
                    </Col>
                    <Col xs={6}>
                      <strong className={'mr-1'}>
                        <Trans>End Date</Trans>:
                      </strong>
                      {dateFormatter(project.endDate)}
                    </Col>
                  </Row>
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Provider</Trans>:
                  </strong>
                  {providerName(get(project, 'provider', null))}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Address</Trans>:
                  </strong>
                  {project.lat && project.lng ? (
                    isMobileOnly ? (
                      <a
                        href={`https://www.waze.com/ul?ll=${project.lat}%2C${project.lng}&zoom=17`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'address', '-')}
                      </a>
                    ) : (
                      <a
                        href={`https://www.google.com/maps/place/${get(
                          project,
                          'address',
                          ''
                        )}/@=${project.lat},${project.lng}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {get(project, 'address', '-')}
                      </a>
                    )
                  ) : (
                    get(project, 'address', '-')
                  )}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Description</Trans>:
                  </strong>
                  {get(project, 'description', '')}
                </li>
              </ul>
            </div>
          ) : null}
        </CardBody>
        <CardFooter className={'text-center pt-0'}>
          {postulated ? (
            <Badge color="warning" className={'alert-postulate'}>
              <h5 className={'m-0'}>
                <strong>
                  <Trans>You are already postulated in this project</Trans>
                </strong>
              </h5>
            </Badge>
          ) : (
            <Button color="info" onClick={() => this.props.postulate()}>
              <Trans>Postulate</Trans>
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(ProjectInfoCard));
