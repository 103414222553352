import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans, translate } from 'react-i18next';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button
} from 'reactstrap';
import get from 'lodash/get';
import defaultImage from '../../../../assets/img/image_placeholder.jpg';
import Gallery from '../../../Images/Gallery';

class MaterialModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: null,
      calc: { ttc: 0, tva: 0, result: 0 },
      showGallery: false
    };
  }

  componentWillMount() {
    let { material } = this.props;
    this.setState({ material });
  }

  cleanModal() {
    this.setState({
      material: null,
      nameMatValid: null,
      cantMatValid: null,
      unitMatValid: null,
      unitPriceMatValid: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { material } = this.state;

    this.setState({
      material: { ...material, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let name = this.nameMat;
    let amount = this.cantMat;
    let unit = this.unitMat;
    let unitPrice = this.unitPriceMat;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [unitPrice.attributes.getNamedItem('namevalid').value]: unitPrice.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [unit.attributes.getNamedItem('namevalid').value]: unit.validity.valid
        ? 'has-success'
        : 'has-danger',
      [amount.attributes.getNamedItem('namevalid').value]: amount.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      unitPrice.validity.valid &&
      unit.validity.valid &&
      amount.validity.valid
    );
  }

  onSaveMaterial() {
    let { material } = this.state;
    if (this.validate()) {
      this.cleanModal();
      material.id || material._id
        ? this.props.onUpdate(material)
        : this.props.onConfirm(material);
    }
  }

  calcHT() {
    let { calc } = this.state;
    if (calc.tva && calc.ttc) {
      let percent = calc.ttc * (calc.tva / 100);
      this.setState({
        calc: {
          ...calc,
          result: calc.ttc - percent
        }
      });
    }
  }

  render() {
    let { material, calc, showGallery } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {material && (material.id || material._id)
            ? t('Edit Material')
            : t('New Material')}
        </ModalHeader>
        <ModalBody>
          {material ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <div
                        className="avatar-image"
                        style={{
                          backgroundImage: `url('${get(
                            material,
                            'image',
                            defaultImage
                          )}')`
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <Button
                        color="info"
                        onClick={() => this.setState({ showGallery: true })}
                      >
                        <Trans>Gallery</Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.nameMatValid
                        }
                      >
                        <Label>
                          <Trans>Name</Trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.nameMat = node)}
                          namevalid="nameMatValid"
                          value={material.name || ''}
                          required="required"
                          disabled={material && (material.id || material._id)}
                          onChange={event =>
                            this.onChange(
                              'name',
                              'nameMatValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.cantMatValid
                        }
                      >
                        <Label>Amount</Label>
                        <Input
                          type="number"
                          innerRef={node => (this.cantMat = node)}
                          namevalid="cantMatValid"
                          value={material.cant || ''}
                          step={0.01}
                          min={0.01}
                          required="required"
                          onChange={event =>
                            this.onChange(
                              'cant',
                              'cantMatValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.unitMatValid
                    }
                  >
                    <Label>Unit</Label>
                    <Input
                      type="text"
                      innerRef={node => (this.unitMat = node)}
                      namevalid="unitMatValid"
                      value={material.unit || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'unit',
                          'unitMatValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.unitPriceMatValid
                    }
                  >
                    <Label>Unit Price</Label>
                    <Input
                      type="number"
                      innerRef={node => (this.unitPriceMat = node)}
                      namevalid="unitPriceMatValid"
                      value={material.unit_price || ''}
                      step={0.01}
                      min={0}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'unit_price',
                          'unitPriceMatValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12}>
                  <p>
                    <Trans>Calculate HT</Trans>
                  </p>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup className={'has-label'}>
                    <Input
                      type="number"
                      placeholder={`${t('TTC')} (#)`}
                      step={0.01}
                      min={0.01}
                      value={calc.ttc || 0}
                      onChange={event =>
                        this.setState(
                          {
                            calc: {
                              ...calc,
                              ttc: event.target.value
                            }
                          },
                          () => this.calcHT()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup className={''}>
                    <Input
                      type="number"
                      placeholder={`${'TVA'} (%)`}
                      value={calc.tva || 0}
                      step={1}
                      min={0}
                      onChange={event =>
                        this.setState(
                          {
                            calc: {
                              ...calc,
                              tva: event.target.value
                            }
                          },
                          () => this.calcHT()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <p>
                    <Trans>HT</Trans>
                    {': '}
                    {calc.result}
                  </p>
                </Col>
              </Row>
            </Form>
          ) : null}

          {showGallery ? (
            <Gallery
              selectImage={url =>
                this.setState(prevState => {
                  return {
                    ...prevState,
                    showGallery: false,
                    material: {
                      ...prevState.material,
                      image: url
                    }
                  };
                })
              }
              closeGallery={() =>
                this.setState(prevState => {
                  return { ...prevState, showGallery: false };
                })
              }
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveMaterial()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(MaterialModal));
