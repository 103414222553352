import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
import { AlertConfirm, Spinner, TableCustom } from '../../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';
import {
  duplicateDevis,
  loadAllDevis,
  removeDevis,
  saveOneDevis
} from '../../../helpers/actions/projects';
import get from 'lodash/get';
import { dateFormatter, moneyFormatter } from '../../../helpers/formatters';
import DevisStatusModal from './DevisStatusModal';
import PreviewModal from './PreviewModal';

class DevisList extends Component {
  state = {
    loading: false,
    project: this.props.project,
    devis: [],
    user: null,
    devisPreview: null,
    devisDelete: null,
    devisStatus: false
  };

  load() {
    this.setState({ loading: true });

    let { project } = this.state,
      { user, dispatch } = this.props;

    dispatch(
      loadAllDevis(project._id, {
        include: ['user'],
        fields: ['title', 'created_at', 'user', 'status', 'base', 'price']
      })
    )
      .then(({ data }) => this.setState({ devis: data, user, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.load();
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ user: nextProps.userId });
  }

  duplicateDevi(devi) {
    let { dispatch, project } = this.props;
    dispatch(duplicateDevis(project._id, devi))
      .then(() => this.load())
      .catch(err => {});
  }

  handlerDeletDevis(d) {
    let id = d._id;
    this.setState({ devisDelete: id });
  }

  remove() {
    this.setState({ loading: true });

    let { dispatch, project } = this.props;

    dispatch(removeDevis(project._id, this.state.devisDelete))
      .then(() => this.setState({ devisDelete: null }, () => this.load()))
      .catch(() => this.setState({ devisDelete: null, loading: false }));
  }

  getDevisData() {
    let { devis, user } = this.state,
      { project, basePath, t } = this.props;

    return devis.map((d, key) => {
      return {
        className: '',
        data: [
          {
            className: '',
            fixed: true,
            value: (
              <Link to={`/${basePath}/${project._id}/devis/${d._id}/details`}>
                {get(d, 'title', '')}
              </Link>
            )
          },
          {
            className: '',
            fixed: true,
            value: dateFormatter(d.created_at)
          },
          {
            className: '',
            fixed: false,
            value: get(d.user, 'displayName', '')
          },
          {
            className: '',
            fixed: false,
            value:
              !['APPROVED', 'SENT'].includes(d.status) &&
              (d.user && d.user._id === user) ? (
                <span
                  className={'status-button'}
                  onClick={() =>
                    this.setState(ps => ({ ...ps, devisStatus: d }))
                  }
                >
                  {t(get(d, 'status', ''))}
                </span>
              ) : (
                t(get(d, 'status', ''))
              )
          },
          {
            className: 'text-center',
            fixed: false,
            value: d.base ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(get(d, 'price.proHt', 0))
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(
              get(d, 'price.proHt', 0) + get(d, 'price.materials', 0)
            )
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Link
                key={`details-${key}`}
                id={`details-${key}`}
                className="btn btn-icon ml-1 btn-info btn-sm"
                to={`/${basePath}/${project._id}/devis/${d._id}/details`}
              >
                <i className="fa fa-eye icon-action" />
              </Link>,
              <UncontrolledTooltip
                key={`tooltip-details-${key}`}
                placement="left"
                target={`details-${key}`}
                delay={0}
              >
                <Trans>Details</Trans>
              </UncontrolledTooltip>
            ].concat([
              <UncontrolledDropdown key={`more-${key}`}>
                <DropdownToggle
                  color="info"
                  className="ml-1 btn-sm dropdown-icon"
                  caret
                >
                  <i className="fa fa-ellipsis-h icon-action mr-1" />
                </DropdownToggle>
                <DropdownMenu>
                  {!['APPROVED', 'SENT'].includes(d.status) &&
                  (d.user && d.user._id === user)
                    ? [
                        <DropdownItem
                          key={'DropdownItem-Status'}
                          onClick={() =>
                            this.setState(ps => ({ ...ps, devisStatus: d }))
                          }
                        >
                          <Trans>Change Status</Trans>
                        </DropdownItem>
                        // <DropdownItem
                        //   key={'DropdownItem-Delete'}
                        //   onClick={() => this.handlerDeletDevis(d)}
                        // >
                        //   <Trans>Delete</Trans>
                        // </DropdownItem>
                      ]
                    : null}
                  {/* <DropdownItem onClick={() => this.duplicateDevi(d._id)}>
                            <Trans>Duplicate</Trans>
                          </DropdownItem> */}
                  <DropdownItem
                    onClick={() => this.setState({ devisPreview: d._id })}
                  >
                    <Trans>Preview</Trans>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to={`/${basePath}/${project._id}/devis/${d._id}/print`}
                    >
                      <Trans>Print</Trans>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ])
            // value: (!['APPROVED', 'SENT'].includes(d.status) &&
            // (d.user && d.user._id === user)
            //   ? [
            //       <Link
            //         key={`edit-${key}`}
            //         id={`edit-${key}`}
            //         className="btn btn-icon ml-1 btn-info btn-sm"
            //         to={`/${basePath}/${project._id}/devis/${d._id}`}
            //       >
            //         <i className="fas fa-pencil-alt icon-action" />
            //       </Link>,
            //       <UncontrolledTooltip
            //         key={`tooltip-edit-${key}`}
            //         placement="left"
            //         target={`edit-${key}`}
            //         delay={0}
            //       >
            //         <Trans>Edit</Trans>
            //       </UncontrolledTooltip>
            //     ]
            //   : [
            //       <Link
            //         key={`details-${key}`}
            //         id={`details-${key}`}
            //         className="btn btn-icon ml-1 btn-info btn-sm"
            //         to={`/${basePath}/${project._id}/devis/${d._id}/details`}
            //       >
            //         <i className="fa fa-eye icon-action" />
            //       </Link>,
            //       <UncontrolledTooltip
            //         key={`tooltip-details-${key}`}
            //         placement="left"
            //         target={`details-${key}`}
            //         delay={0}
            //       >
            //         <Trans>Details</Trans>
            //       </UncontrolledTooltip>
            //     ]
            // ).concat([
            //   <UncontrolledDropdown key={`more-${key}`}>
            //     <DropdownToggle
            //       color="info"
            //       className="ml-1 btn-sm dropdown-icon"
            //       caret
            //     >
            //       <i className="fa fa-ellipsis-h icon-action mr-1" />
            //     </DropdownToggle>
            //     <DropdownMenu>
            //       {!['APPROVED', 'SENT'].includes(d.status) &&
            //       (d.user && d.user._id === user)
            //         ? [
            //             <DropdownItem
            //               key={'DropdownItem-Status'}
            //               onClick={() =>
            //                 this.setState(ps => ({ ...ps, devisStatus: d }))
            //               }
            //             >
            //               <Trans>Change Status</Trans>
            //             </DropdownItem>,
            //             <DropdownItem
            //               key={'DropdownItem-Delete'}
            //               onClick={() => this.handlerDeletDevis(d)}
            //             >
            //               <Trans>Delete</Trans>
            //             </DropdownItem>
            //           ]
            //         : null}
            //       <DropdownItem onClick={() => this.duplicateDevi(d._id)}>
            //         <Trans>Duplicate</Trans>
            //       </DropdownItem>
            //       <DropdownItem
            //         onClick={() => this.setState({ devisPreview: d._id })}
            //       >
            //         <Trans>Preview</Trans>
            //       </DropdownItem>
            //       <DropdownItem>
            //         <Link
            //           to={`/${basePath}/${project._id}/devis/${d._id}/print`}
            //         >
            //           <Trans>Print</Trans>
            //         </Link>
            //       </DropdownItem>
            //     </DropdownMenu>
            //   </UncontrolledDropdown>
            // ])
          }
        ]
      };
    });
  }

  changeDevisStatus(value) {
    this.setState(ps => ({ ...ps, loading: true }));

    let { devisStatus } = this.state,
      { dispatch, project } = this.props;

    let status = value ? value : 'CREATED';

    dispatch(
      saveOneDevis(project._id, {
        status: status,
        _id: devisStatus._id,
        project: project._id
      })
    )
      .then(() => {
        this.setState(
          ps => ({
            ...ps,
            devisStatus: false
          }),
          () => this.load()
        );
      })
      .catch(() =>
        this.setState(ps => ({ ...ps, devisStatus: false, loading: false }))
      );
  }

  render() {
    let { loading, devis, devisPreview, devisDelete, devisStatus } = this.state,
      { project, t } = this.props;
    let rows = this.getDevisData();

    return [
      <Card key="devisList" className={'card-flex'}>
        {loading ? <Spinner /> : null}
        <CardHeader>
          <h6>
            <Trans>Devis</Trans>
          </h6>
          {/* <Button
            className="float-right"
            size="sm"
            color={'info'}
            href={`/${basePath}/${project._id}/devis`}
          >
            <Trans>Add</Trans>
          </Button> */}
          {devis && devis.length && devis.find(d => d.status === 'APPROVED') ? (
            <Button
              className="float-right"
              size="sm"
              color={'info'}
              href={`/my-projects/${project._id}/orders`}
            >
              <Trans>Orders</Trans>
            </Button>
          ) : null}
        </CardHeader>
        <CardBody>
          <TableCustom
            minWidth={767}
            className={'devi-item-material-table'}
            accordion={true}
            heads={[
              { value: t('Title') },
              { value: t('Date') },
              { value: t('User') },
              { value: t('Status') },
              { value: t('Base'), className: 'text-center' },
              { value: t('Pro HT'), className: 'text-right' },
              { value: t('Pro HT + Material'), className: 'text-right' },
              { value: '', className: 'text-right' }
            ]}
            rows={rows}
            emptyMessage={t('No devis found')}
          />
        </CardBody>
      </Card>,
      !!devisPreview ? (
        <PreviewModal
          key={'previewModal'}
          show={!!devisPreview}
          project={project}
          devisId={devisPreview}
          dispatch={this.props.dispatch}
          onClose={() => this.setState({ devisPreview: null })}
        />
      ) : null,
      devisDelete ? (
        <AlertConfirm
          key={'alertConfirm'}
          message={'The devis cannot be recovered'}
          onCancel={() => this.setState({ devisDelete: null })}
          onConfirm={() => this.remove()}
        />
      ) : null,
      !!devisStatus ? (
        <DevisStatusModal
          key={'statusModal'}
          show={!!devisStatus}
          status={devisStatus ? devisStatus.status : null}
          onCancel={() => this.setState(ps => ({ ...ps, devisStatus: false }))}
          onConfirm={value => this.changeDevisStatus(value)}
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(DevisList));
