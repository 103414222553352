import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import {
  clientName,
  dateFormatter,
  providerName
} from '../../helpers/formatters';

class ProjectCard extends Component {
  render() {
    let { project } = this.props;

    return (
      <Card className="card-tasks card-footer-down equal-height">
        <CardHeader className={'flex-auto'}>
          <h5 className="title gray">{get(project, 'name', '')}</h5>
          <p className="card-category">
            {project.client.length ? clientName(project.client[0]) : ''}
          </p>
        </CardHeader>
        <CardBody className={'flex-0'}>
          <Row>
            <Col xs={6}>
              <p className="justify-content-center">
                <strong>
                  <Trans>Start Date</Trans>:
                </strong>
                <br />
                {dateFormatter(project.startDate)}
              </p>
              <p className="justify-content-center">
                <strong>
                  <Trans>Distance</Trans>:
                </strong>
                <br />
                {project.distance}
              </p>
            </Col>
            <Col xs={6}>
              <p className="justify-content-center">
                <strong>
                  <Trans>End Date</Trans>:
                </strong>
                <br />
                {dateFormatter(project.endDate)}
              </p>
              <p className="justify-content-center">
                <strong>
                  <Trans>Provider</Trans>:
                </strong>
                <br />
                {providerName(project.provider)}
              </p>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className={'flex-0'}>
          <hr className={'mt-0'} />
          <Link
            className="btn btn-info"
            to={`/market/${this.props.project._id}/postulate`}
          >
            <Trans>Postulate</Trans>
          </Link>
          <Button
            onClick={() => this.props.modalRoute()}
            className={'ml-2'}
            color={'default'}
          >
            <Trans>Map</Trans>
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(ProjectCard));
