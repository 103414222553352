import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { market } from '../../helpers/actions/projects';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PaginationCustom, PanelHeader, Spinner } from '../../components';
import Datetime from 'react-datetime';
import moment from 'moment';
import { withFirebase } from 'react-redux-firebase';
import Project from './ProjectCard';
import LocationModal from './LocationModal';
import { UserData } from '../../helpers/api';

const enhance = connect(({ firebase }) => ({ firebase }));

class Market extends Component {
  state = {
    loading: true,
    projects: [],
    count: 0,
    page: 1,
    limit: 8,
    projectName: '',
    clientName: '',
    dateFrom: null,
    dateTo: null,
    user: null,
    projectSelected: null
  };

  async load() {
    this.setState({ loading: true });

    let { page, limit, projectName, clientName, dateFrom, dateTo } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    Promise.all([
      dispatch(
        market({
          limit,
          offset,
          name: projectName,
          displayName: clientName,
          dateFrom,
          dateTo
        })
      ),
      UserData()
    ])
      .then(([{ data, meta }, { user }]) => {
        this.setState({ projects: data, ...meta, loading: false, user });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    let {
      loading,
      projects,
      page,
      limit,
      count,
      user,
      projectSelected
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Project</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.projectName}
                        onChange={event =>
                          this.setState(
                            {
                              projectName: event.target.value,
                              page: 1
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Client</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.clientName}
                        onChange={event =>
                          this.setState(
                            {
                              clientName: event.target.value,
                              page: 1
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Start Date</Trans>
                      </Label>
                      <Datetime
                        locale={'fr'}
                        value={moment(this.state.dateFrom)}
                        closeOnSelect={true}
                        onChange={value =>
                          this.setState(
                            {
                              dateFrom: moment(value).isValid() ? value : null,
                              page: 1
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>End Date</Trans>
                      </Label>
                      <Datetime
                        locale={'fr'}
                        value={moment(this.state.dateTo)}
                        closeOnSelect={true}
                        onChange={value =>
                          this.setState(
                            {
                              dateTo: moment(value).isValid() ? value : null,
                              page: 1
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {projects.length > 0 ? (
            <div>
              <Row>
                {projects.map(project => (
                  <Col
                    key={project._id}
                    xs={12}
                    md={6}
                    lg={4}
                    className="text-center equal-height"
                  >
                    <Project
                      project={project}
                      modalRoute={() =>
                        this.setState({ projectSelected: project })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No projects found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {projectSelected && user ? (
            <LocationModal
              project={projectSelected}
              user={user}
              onCancel={() => this.setState({ projectSelected: null })}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(
  translate('translations-fr')(enhance(withFirebase(Market)))
);
