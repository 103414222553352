import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { PanelHeader, Spinner, Button } from '../../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { loadOneDevis, one, pdf } from '../../../helpers/actions/projects';
import { getUrlParam } from '../../../helpers/urlParams';
import { isMobileOnly } from 'react-device-detect';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DevisPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: 'pro',
      devis: {
        groups: [],
        pro: {}
      },
      project: {
        client: {
          phone: {}
        }
      },
      scale: 1.4,
      numPages: null,
      pageNumber: 1
    };
  }

  load() {
    let type = getUrlParam('type', 'pro');
    this.setState({ loading: true, type });

    let { dispatch, match } = this.props;
    Promise.all([
      dispatch(
        loadOneDevis(match.params.id, match.params.devisId, {
          fields: [
            'title',
            'price',
            'base',
            'project',
            'groups',
            'parent',
            'status',
            'user',
            'created_at',
            'updated_at'
          ]
        })
      ),
      dispatch(
        one(match.params.id, {
          include: ['none'],
          fields: ['name', 'status']
        })
      )
    ])
      .then(([devis, project]) => {
        dispatch(pdf(project._id, devis._id))
          .then(params =>
            this.setState({ devis, project, params, loading: false })
          )
          .catch(() => this.setState({ loading: false }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  onDocumentLoadSuccess = response => {
    if (response && response.numPages) {
      this.setState({ numPages: response.numPages });
    }
  };

  onDocumentLoadError = err => {
    console.log(err);
  };

  changePage(pageNumber) {
    this.setState(ps => ({ ...ps, pageNumber }));
  }

  scale(dir) {
    if (dir && this.state.scale < 3) {
      this.setState(ps => ({ ...ps, scale: ps.scale + 0.2 }));
    } else if (!dir && this.state.scale >= 0.6) {
      this.setState(ps => ({ ...ps, scale: ps.scale - 0.2 }));
    }
  }

  render() {
    let { loading, project, params, type, numPages, scale } = this.state,
      { t } = this.props;

    let url = null,
      filename = `${t('Devis')}`;

    if (params) {
      url =
        params.url +
        '?type=' +
        type +
        '&apiRole=pro' +
        '&apiKey=' +
        params.httpHeaders.Authorization.replace('Bearer ', '');
    }

    if (project && project.name) {
      filename = `${t('Devis')} - ${project.name.replace(' ', '_')}.pdf`;
    }

    let pages = [];

    if (url && numPages && numPages > 0) {
      for (let i = 1; i <= numPages; i++) {
        pages.push(
          <Page
            key={'pdf-page-' + i}
            pageNumber={i}
            renderMode={'svg'}
            scale={scale}
            loading={
              <div className={'p-4 text-center'}>
                <i className="fas fa-circle-notch fa-spin fa-2x"></i>
              </div>
            }
          />
        );
      }
    }

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            {isMobileOnly ? (
              [
                <CardHeader
                  key="mobile-pdf-header"
                  className={'pb-1 p-2 text-center'}
                >
                  <Button
                    disabled={url && numPages ? false : true}
                    color={'info'}
                    size={'sm m-0 mr-2'}
                    onClick={() => this.scale(true)}
                  >
                    <h4
                      style={{ lineHeight: 0.7 }}
                      className={'m-0 p-0 font-weight-bold'}
                    >
                      +
                    </h4>
                  </Button>
                  <Button
                    disabled={url && numPages ? false : true}
                    color={'info'}
                    size={'sm m-0'}
                    onClick={() => this.scale(false)}
                  >
                    <h4
                      style={{ lineHeight: 0.7 }}
                      className={'m-0 p-0 font-weight-bold'}
                    >
                      -
                    </h4>
                  </Button>
                </CardHeader>,
                <CardBody key="mobile-pdf-body" className={'p-1'}>
                  {url ? (
                    <div className="pdf-full">
                      <Document
                        file={url}
                        scale={1.4}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        onLoadError={this.onDocumentLoadError}
                        loading={
                          <div className={'p-4 text-center'}>
                            <i className="fas fa-circle-notch fa-spin fa-2x"></i>
                          </div>
                        }
                      >
                        {numPages ? pages : null}
                      </Document>
                    </div>
                  ) : null}
                </CardBody>
              ]
            ) : (
              <CardBody className="pdf-full">
                {url ? (
                  <iframe title={filename} className="pdf-holder" src={url} />
                ) : null}
              </CardBody>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(DevisPrint));
