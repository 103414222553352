import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import {
  Accordion,
  AlertConfirm,
  CustomGallery,
  PanelHeader,
  PanelTitle,
  Spinner,
  TableCustom
} from '../../components';
import { withFirebase } from 'react-redux-firebase';
import get from 'lodash/get';
import { one, postulate } from '../../helpers/actions/projects';
import AssignSupervisor from '../Projects/Supervisor/AssignSupervisor';
import ProjectInfoCard from './ProjectInfoCard';
import Maps from '../Projects/Map3D/Maps';
import DevisList from './DevisList';
import { dateFormatter } from '../../helpers/formatters';
import { cloneDeep } from 'lodash';
import { UserData } from '../../helpers/api';

const enhance = connect(
  // Map redux state to component props
  ({ firebase, fb: { auth, profile } }) => ({
    firebase,
    auth,
    profile
  })
);

class Postulate extends Component {
  state = {
    loading: true,
    user: null,
    project: {
      operations: [],
      maps: null
    },
    hTabs: 'medias',
    postulated: false,
    postulate: false,
    labels: ['important', 'pobleme', 'note']
  };

  load() {
    this.setState({ loading: true });

    let { user } = this.state,
      { match, dispatch } = this.props,
      postulated = false;

    dispatch(one(match.params.id))
      .then(project => {
        if (project.postulates && project.postulates.length && user) {
          let me = project.postulates.indexOf(user);
          postulated = !!(me > -1);
        }
        this.setState({ project, postulated, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.setState({ loading: true });

    UserData()
      .then(({ user }) => {
        this.setState({ user: user._id }, () => this.load());
      })
      .catch(err => {});
  }

  getNotesData() {
    let { project } = this.state,
      { t } = this.props;

    return project.notes
      ? project.notes.map((note, key) => {
          return {
            className: '',
            data: [
              { className: '', value: note.title },
              { className: '', fixed: true, value: t(note.type) },
              {
                className: 'text-center',
                fixed: false,
                value: note.completed ? <i className="fa fa fa-check" /> : null
              },
              {
                className: '',
                value: note.user && note.user[0] ? note.user[0].displayName : ''
              },
              {
                className: '',
                fixed: false,
                value: dateFormatter(note.updatedAt || note.createdAt)
              },
              {
                className: 'text-center',
                fixed: false,
                value: note.send ? <i className="fa fa fa-check" /> : null
              },
              {
                className: '',
                value: <div dangerouslySetInnerHTML={{ __html: note.text }} />
              }
            ]
          };
        })
      : [];
  }

  getMedias() {
    let { project } = this.state;

    if (project.medias) {
      let imagesByLabel = [];

      project.medias.forEach(media => {
        if (imagesByLabel[media.label]) {
          imagesByLabel[media.label].push({
            id: media._id,
            valide: media.valide,
            label: media.label ? media.label : '',
            name: media.name,
            src: media.source,
            points: media.points,
            isSelected: false
          });
        } else {
          imagesByLabel[media.label] = [
            {
              id: media._id,
              valide: media.valide,
              label: media.label ? media.label : '',
              name: media.name,
              src: media.source,
              points: media.points,
              isSelected: false
            }
          ];
        }
      });
      return imagesByLabel;
    }

    return [];
  }

  postulate() {
    this.setState({ loading: true });

    let { project } = this.state,
      { dispatch } = this.props;

    dispatch(postulate(project._id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { loading, project, hTabs, postulated, postulate } = this.state,
      { t } = this.props;
    let notes = this.getNotesData();
    let imagesByLabel = this.getMedias();
    let keys = Object.keys(imagesByLabel);

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <PanelTitle title={get(project, 'name', '')} backPath={`/market`} />
          <Row>
            <Col xs={12} md={8} className="flex-column mb-4">
              <ProjectInfoCard
                project={project}
                postulated={postulated}
                postulate={() => this.setState({ postulate: true })}
              />
            </Col>
            <Col xs={12} md={4} className="flex-column mb-4">
              <AssignSupervisor project={project} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Nav pills className="nav-pills-info project-pills">
                    <NavItem>
                      <NavLink
                        className={hTabs === 'medias' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'medias' })}
                      >
                        <Trans>Medias</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'notes' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'notes' })}
                      >
                        <Trans>Notes</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'maps' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'maps' })}
                      >
                        <Trans>Maps</Trans>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <hr />
                  <TabContent activeTab={hTabs}>
                    <TabPane tabId="medias">
                      {keys.length > 0 ? (
                        keys.map((label, index) => {
                          if (imagesByLabel[label].length)
                            return (
                              <Accordion
                                key={'accordion-gallery-label-' + label}
                                plain
                                defaultOpened={index === 0 ? 0 : -1}
                                components={[
                                  {
                                    title: (
                                      <h6 style={{ display: 'inline' }}>
                                        {label}
                                      </h6>
                                    ),
                                    text: (
                                      <CustomGallery
                                        key={'gallery-label-' + label}
                                        images={cloneDeep(imagesByLabel[label])}
                                        updateMedias={() => {}}
                                        selectImage={() => {}}
                                        selectable={false}
                                        crudPoints={false}
                                      />
                                    )
                                  }
                                ]}
                              />
                            );
                          return null;
                        })
                      ) : (
                        <p className={'text-not-found text-center'}>
                          <Trans>No medias found</Trans>
                        </p>
                      )}
                    </TabPane>
                    <TabPane tabId="notes">
                      {project && project._id ? (
                        <TableCustom
                          minWidth={767}
                          className={'devi-item-material-table'}
                          accordion={true}
                          heads={[
                            { value: t('Title') },
                            { value: t('Type') },
                            { value: t('Completed'), className: 'text-center' },
                            { value: t('User') },
                            { value: t('Date') },
                            { value: t('Sent'), className: 'text-center' },
                            { value: t('Description') }
                          ]}
                          rows={notes}
                          emptyMessage={t('No notes found')}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="maps">
                      {project &&
                      project._id &&
                      project.maps &&
                      hTabs === 'maps' ? (
                        <Maps
                          projectId={project._id}
                          maps={project.maps}
                          className={'card-plain user-data'}
                        />
                      ) : null}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              {project && project._id ? (
                <DevisList project={project} basePath={'market'} />
              ) : null}
            </Col>
          </Row>

          {!postulate ? null : (
            <AlertConfirm
              message={
                'Remember that postulation cannot be canceled once you have postulated'
              }
              onCancel={() => this.setState({ postulate: false })}
              onConfirm={() =>
                this.setState({ postulate: false }, () => this.postulate())
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default translate('translations-fr')(enhance(withFirebase(Postulate)));
